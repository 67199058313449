import { Routes } from '@angular/router';
import { RouteGuard } from './route-guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent),
    title: 'Login',
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./components/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
    title: 'Password dimenticata',
  },
  {
    path: 'upload-file',
    loadComponent: () => import('./components/upload-file/upload-file.component').then(m => m.UploadFileComponent),
    canActivate: [RouteGuard],
  },
  {
    path: 'change-password',
    loadComponent: () => import('./components/change-password/change-password.component').then(m => m.ChangePasswordComponent),
    canActivate: [RouteGuard],
    title: 'Cambia password',
  },
  {
    path: 'change-password/:token',
    loadComponent: () => import('./components/change-password/change-password.component').then(m => m.ChangePasswordComponent),
    title: 'Cambia password',
  },
  {
    path: 'confirm-send',
    loadComponent: () => import('./components/confirm-send/confirm-send.component').then(m => m.ConfirmSendComponent),
    canActivate: [RouteGuard],
    title: 'Conferma invio',
  },
  {
    path: 'uploaded',
    loadComponent: () => import('./components/uploads-list/uploads-list.component').then(m => m.UploadsListComponent),
    canActivate: [RouteGuard],
    title: 'Rilevazioni',
  },
];
