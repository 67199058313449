{
  "name": "portal-babylo",
  "version": "2.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:prod": "ng build --configuration production --aot --output-hashing all",
    "build:test": "ng build --configuration test --aot --output-hashing all",
    "watch": "ng build --watch --configuration development",
    "test": "ng test --watch=false",
    "lint": "ng lint",
    "lintFix": "ng lint --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@azure/storage-blob": "^12.14.0",
    "@fortawesome/fontawesome-pro": "^6.7.1",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@popperjs/core": "^2.11.6",
    "bootstrap": "^5.2.3",
    "bootstrap-icons": "^1.10.3",
    "ngx-cookie-service": "^17.0.1",
    "prettier": "^3.2.5",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.4.2",
    "@angular-eslint/eslint-plugin": "18.4.2",
    "@angular-eslint/eslint-plugin-template": "18.4.2",
    "@angular-eslint/schematics": "18.4.2",
    "@angular-eslint/template-parser": "18.4.2",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@types/jasmine": "~4.3.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.5.4"
  }
}
