import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs';
import { LoginService } from './service/login.service';
import { UserService } from './service/user.service';
import { UtilsService } from './service/utils.service';
import { FormGroup } from '@angular/forms';
import { CustomerInfo } from './model/customer-info';
import { NgbModal, NgbProgressbar, NgbToast, NgbToastHeader, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ToastService } from './service/toast.service';
import { NgClass } from '@angular/common';
import { ToastContainerComponent } from './components/utils/toast-container/toast-container.component';

import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    NgbTooltip,
    NgClass,
    RouterOutlet,
    NgbToast,
    NgbProgressbar,
    ToastContainerComponent,
    NgbToastHeader,
    RouterLink,
  ],
})
export class AppComponent implements OnInit {
  title = 'PortalBabylo';

  appVersion = packageInfo.version;

  username: string | undefined;
  userAvatar: string | undefined;
  customers: CustomerInfo[] = [];

  currentCustomer: CustomerInfo | undefined;

  hiddenMenu = true;

  isOperation = true;

  userMenu: { label: string; route: string }[] = [];

  toastInfo:
    | {
    isUploading: boolean;
    percentage: number | null;
    uploadForm: FormGroup | null;
    nomeFile: string | null;
  }
    | undefined;

  currentPage: string = '';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.routeConfig?.title) {
            routeTitle = route.snapshot.routeConfig?.title.toString();
          }

          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        this.currentPage = title;
      });

    this.userService.customers.subscribe((customers) => {
      this.customers = customers;
      if (customers.length == 1) {
        this.userService.customerCode.next(customers[0]);
      }
    });
    this.userService.username.subscribe((val) => {
      this.username = val.username;
      this.userAvatar = val.userAvatar;
    });

    this.userService.customerCode.subscribe((code) => {
      if (code) this.currentCustomer = code;
    });

    this.userService.userMenu.subscribe((menuList) => {
      if (menuList) this.userMenu = menuList;
    });

    userService.userGroup.subscribe((group) => {
      if (group) this.isOperation = group.toLowerCase() == 'op';
    });

    this.router.events
      .pipe(filter((events) => events instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          (event.urlAfterRedirects &&
            (event.urlAfterRedirects === '/login' ||
              event.urlAfterRedirects === '/forgot-password')) ||
          event.url === '/' ||
          event.url === '/login' ||
          event.url === '/forgot-password' ||
          new RegExp('change-password/\\b').test(event.url)
        ) {
          this.hiddenMenu = true;
        } else {
          this.hiddenMenu = false;
        }
      });
  }

  getIconFromCurrentPage() {
    switch (this.currentPage.toLowerCase()) {
      case 'rilevazioni':
        return 'fa-light fa-user-police';
      default:
        return 'd-none';
    }
  }

  getNomeFile(arg0: string) {
    return arg0.substring(arg0.indexOf('-') + 1);
  }

  ngOnInit() {
    if (!this.username || this.username.length == 0) {
      this.loginService.getUserInfo().subscribe({
        next: (resp) => {
          if (resp.userGroup.toLowerCase() == 'op') {
            this.isOperation = true;
          } else {
            this.isOperation = false;
          }

          this.userService.customers.next(resp.customers);
          this.userService.username.next({ username: resp.username, userAvatar: resp.userAvatar });
          this.userService.userGroup.next(resp.userGroup);
          this.userService.userMenu.next(resp.userMenus);
        },
        // error: (err) => this.utilsService.httpErrorMessage(err),
      });
    }

    this.userService.uploadStatus.subscribe({
      next: (resp) => {
        this.toastInfo = resp;
      },
    });
  }

  toNumber(arg0: string): number {
    return Number.parseFloat(arg0);
  }

  updateProgress(progressbar: HTMLDivElement) {
    if (
      this.toastInfo &&
      this.toastInfo.isUploading &&
      this.toastInfo.percentage
    ) {
      const percentage = this.toastInfo.percentage.toFixed(2);

      progressbar.style.width = percentage;
      progressbar.textContent = `${percentage}%`;
      progressbar.setAttribute('aria-valuenow', percentage.toString());
    }
  }

  changeCustomer(customer: { descrizione: string; cod: string }) {
    this.userService.customerCode.next(customer);
  }

  logout() {
    sessionStorage.clear();
    this.username = '';
    this.currentCustomer = undefined;
    this.customers = [];

    this.loginService.logout().subscribe({
      next: () => {
        this.router.navigate(['login']);
      },
      error: (err) => this.utilsService.httpErrorMessage(err),
    });
  }

  openUploadPage() {
    this.modalService.open(UploadFileComponent, {
      size: 'lg',
    });

  }

  showSuccess(template: TemplateRef<any>) {
    this.toastService.show({ template, classname: 'bg-toast-success border-success', delay: 5000 });
  }

  protected readonly window = window;

  getInitials(user: string) {
    const initials = user.match(/^([a-z])/);
    if (initials && initials?.length > 0)
      return !(initials) || initials[0].length === 1 ? initials[0].toUpperCase() : initials[0].toUpperCase() + user.charAt(1).toUpperCase();

    return '';
  }

}
